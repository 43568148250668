import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { AccountForm } from '../../../../components/AccountForm';
import CompanyDataForm from '@profile/shared/components/CompanyDataForm/CompanyDataForm.vue';
import { UserDataForm } from '@profile/shared/components/UserDataForm';
import { loadScript, unloadScript } from '@d24/modules/front';
import { ResourceActionFailed } from '@movecloser/front-core';
import { ConnectorErrors } from '@exception/connector-errors';
import { SiteServiceType } from '@service/site';
import { CardsRepositoryType } from '@profile/contracts';
/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let ManageCardsView = class ManageCardsView extends Vue {
    constructor() {
        super(...arguments);
        this.formName = 'manageCardsForm';
        this.isLoading = false;
        this.errors = [];
        this.timer = 0;
        this.successMessage = '';
        this.isPayUInitialized = false;
        this.payU = null;
    }
    get payUConfig() {
        var _a;
        return ((_a = this.$configuration) === null || _a === void 0 ? void 0 : _a.byFile('payments').payu) ||
            {
                sandbox: true,
                posId: ''
            };
    }
    get redirectUri() {
        const route = this.$router.resolve({ name: 'profile.subscription' });
        return [
            this.siteUrl.replace(/^\/|\/$/g, ''),
            route.href.replace(/^\/|\/$/g, '')
        ].join('/');
    }
    get siteUrl() {
        return this.sites.getActiveSite().address;
    }
    onCancel() {
        this.$router.push({ name: 'profile.subscription' });
    }
    async onSubmit() {
        if (!this.payU) {
            return;
        }
        this.isLoading = true;
        this.successMessage = '';
        try {
            const tokenResult = await this.payU.tokenize('MULTI');
            if (tokenResult.status !== 'SUCCESS') {
                const formErrors = tokenResult.error.messages;
                throw new ResourceActionFailed('', ConnectorErrors.Validation, formErrors.reduce((acc, m) => {
                    if (!m.source) {
                        return acc;
                    }
                    return {
                        ...acc,
                        [m.source]: m.message
                    };
                }, {}));
            }
            const result = await this.cardsRepository.add({
                cardToken: tokenResult.body.token,
                redirectUri: this.redirectUri
            });
            this.successMessage = result.message;
            this.timer = 5;
            const timeout = setInterval(() => {
                this.timer--;
                if (!this.timer) {
                    clearInterval(timeout);
                    window.location.href = result.redirect;
                }
            }, 1000);
        }
        catch (error) {
            console.info(error.payload);
            this.$logger(error, 'error');
            this.errors = error.payload;
            this.isLoading = false;
        }
    }
    async destroyPayU() {
        try {
            await unloadScript(`https://secure.${this.payUConfig.sandbox ? 'snd.' : ''}payu.com/javascript/sdk`);
        }
        catch (e) {
            this.$logger(e.message, 'error');
        }
    }
    async initPayU() {
        try {
            await loadScript(`https://secure.${this.payUConfig.sandbox ? 'snd.' : ''}payu.com/javascript/sdk`);
            const optionsForms = {
                cardIcon: true,
                style: {
                    basic: {
                        fontSize: '15px',
                        fontFamily: 'Raleway, Helvetica, Arial, sans-serif'
                    }
                },
                placeholder: {
                    number: 'Nr karty',
                    date: 'MM/YY',
                    cvv: 'Kod CVV'
                },
                lang: 'pl'
            };
            // initialize the SDK by providing your POS ID and create secureForms object
            const payuSdkForms = window.PayU(this.payUConfig.posId, { dev: this.payUConfig.sandbox });
            const woff2 = require('@/assets/fonts/raleway/Raleway-Regular.woff2');
            const woff = require('@/assets/fonts/raleway/Raleway-Regular.woff');
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const secureForms = payuSdkForms.secureForms({
                fonts: [
                    {
                        family: 'Raleway',
                        src: `url("${this.siteUrl + woff2}") format("woff2"), url("${this.siteUrl + woff}") format("woff2)`,
                        style: 'normal',
                        display: 'swap',
                        weight: 400
                    }
                ]
            });
            // create the forms by providing type and options
            const cardNumber = secureForms.add('number', optionsForms);
            const cardDate = secureForms.add('date', optionsForms);
            const cardCvv = secureForms.add('cvv', optionsForms);
            // render the form in selected element
            cardNumber.render('#payu-card-number');
            cardDate.render('#payu-card-date');
            cardCvv.render('#payu-card-cvv');
            this.payU = payuSdkForms;
            this.isPayUInitialized = true;
        }
        catch (e) {
            this.errors = [
                'Błąd inicjalizacji formularza płatności PayU.',
                e.message
            ];
            this.$logger(e, 'error');
        }
    }
};
__decorate([
    Inject(CardsRepositoryType)
], ManageCardsView.prototype, "cardsRepository", void 0);
__decorate([
    Inject(SiteServiceType)
], ManageCardsView.prototype, "sites", void 0);
ManageCardsView = __decorate([
    Component({
        name: 'ManageCardsView',
        components: {
            UserDataForm,
            AccountForm,
            CompanyDataForm,
            Screen
        },
        mounted() {
            this.initPayU();
        },
        destroyed() {
            if (typeof window !== 'undefined') {
                this.destroyPayU();
            }
        }
    })
], ManageCardsView);
export { ManageCardsView };
export default ManageCardsView;
